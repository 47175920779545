<template>
  <div>
    <el-dialog title="确认" append-to-body :visible.sync="dialogVisible" width="30%">
      <div class="requireDialog">
        <p class="el-icon-warning-outline first">确认注销用户: {{ tableData && tableData.accountName }}</p>
        <p class="second">该操作无法撤回</p>
        <p>注: 账号注销后，平台将自动发送短信及邮件通知用户</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goBack" size="mini">取消</el-button>
        <el-button type="danger" @click="requiredHandle" size="mini" :loading="loading">确认注销</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    RequireDialogVisible: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: {},
  watch: {
    RequireDialogVisible: {
      handler(n) {
        this.dialogVisible = n
      }
    },
    dialogVisible: {
      handler(n) {
        this.$emit('update:RequireDialogVisible', n)
      }
    }
  },
  methods: {
    requiredHandle() {
      this.$emit('requiredHandle')
    },
    goBack() {
      this.$emit('update:RequireDialogVisible', false)
    }
  }
}
</script>

<style scoped lang="scss">
.requireDialog {
  p {
    margin: 0;
    margin-bottom: 10px;
  }
  .first {
    line-height: 23px;
    &::before {
      color: red;
      margin-right: 5px;
    }
  }
  .second {
    color: red;
  }
}
</style>
