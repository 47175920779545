<template>
  <div>
    <el-dialog title="账户注销" :visible.sync="dialogVisible" append-to-body width="60%">
      <div class="authenticationDialog">
        <div class="auth-dialog-head">
          <p class="head-title">注销流程</p>
          <p class="head-describe">(①账号条件审核-②用户手机认证-③注销成功)</p>
        </div>
        <p>现在需要用户进行身份认证</p>
        <p>手机号认证: {{ secrecyMobile }}</p>
        <el-form :model="form" :rules="rules" label-width="100px" size="small" width="200px" ref="ruleForm">
          <el-form-item label="验证码" prop="captcha">
            <el-input v-model="form.captcha" class="input-width"></el-input>
            <el-button @click="getTestCode">{{ getCode }}</el-button>
          </el-form-item>
          <el-form-item label="注销原因" prop="otherReason">
            <el-input v-model="form.otherReason" class="input-width" type="textarea" :rows="2" resize="none"></el-input>
          </el-form-item>
        </el-form>
        <div class="auth-dialog-tip">
          <span class="el-icon-warning-outline"></span>
          <span>提示: 账号一旦注销成功，将无法恢复，注销操作不可逆。用户将无法登录此账号，请慎重操作</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goBack">上一步</el-button>
        <el-button type="danger" @click="requestCancel">确认注销</el-button>
      </span>
    </el-dialog>
    <RequireDialog
      :RequireDialogVisible.sync="RequireDialogVisible"
      :tableData="tableData"
      @requiredHandle="requiredHandle"
      :loading="requestLoading"
    ></RequireDialog>
  </div>
</template>

<script>
import RequireDialog from '../requireDialog'
import { getPhoneMessage, userConfirmCancell, activeCancellationValidate } from '@/api/user'
export default {
  components: {
    RequireDialog
  },
  inject: ['init'],
  props: {
    authenticationDialogVisible: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      RequireDialogVisible: false,
      mobile: '',
      form: {
        captcha: '',
        otherReason: '',
        cancellationReason: ['otherReason']
      },
      rules: {
        captcha: [{ required: true, message: '请输入验证吗', trigger: 'blur' }],
        otherReason: [{ required: true, message: '请输入注销原因', trigger: 'blur' }]
      },
      dialogVisible: false,
      getCode: '获取',
      requestLoading: false
    }
  },
  computed: {
    secrecyMobile() {
      let mobileArr = []
      if (this.mobile) {
        mobileArr = [...this.mobile]
      } else {
        return
      }

      let secrecyArr = mobileArr.fill('*', 3, 7)
      return secrecyArr.join('')
    }
  },
  watch: {
    authenticationDialogVisible: {
      handler(n) {
        this.dialogVisible = n
      }
    },
    dialogVisible: {
      handler(n) {
        this.$emit('update:authenticationDialogVisible', n)
      }
    },
    tableData: {
      handler(n) {
        this.mobile = n.mobile
      },
      deep: true
    }
  },
  methods: {
    close() {
      this.$emit('update:authenticationDialogVisible', false)
    },
    requestCancel() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          const { code } = await activeCancellationValidate({
            mobile: this.mobile,
            captcha: this.form.captcha
          })
          if ($SUC({ code })) {
            this.RequireDialogVisible = !this.RequireDialogVisible
          } else {
            return
          }
        } else {
          return false
        }
      })
    },
    goBack() {
      this.$emit('goBack')
      this.close()
    },
    async requiredHandle() {
      this.requestLoading = true
      try {
        let { otherReason, captcha, cancellationReason } = this.form
        const query = {
          otherReason,
          captcha,
          cancellationReason,
          cancellationUserId: this.tableData.id,
          mobile: this.mobile
        }
        const { code } = await userConfirmCancell(query)
        if ($SUC({ code })) {
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败')
        }
      } catch {}
      this.requestLoading = false
      this.RequireDialogVisible = false
      this.close()
      this.init()
    },
    getTestCode() {
      let second = 60
      const timer = setInterval(() => {
        second--
        this.getCode = second
        if (second === 0) {
          this.getCode = '重新获取'
          second = 60
          clearInterval(timer)
        }
      }, 1000)
      //
      getPhoneMessage({ phoneNumber: this.mobile })
    }
  }
}
</script>

<style scoped lang="scss">
.authenticationDialog {
  .auth-dialog-head {
    p {
      display: inline-block;
      margin-top: 0;
    }
    .head-title {
      margin-right: 10px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .el-form {
    .input-width {
      width: 180px;
      margin-right: 10px;
    }
  }
  .auth-dialog-tip {
    text-align: right;
    color: red;
  }
}
</style>
