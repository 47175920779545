var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"账户注销","append-to-body":"","visible":_vm.dialogVisible,"width":"60%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"apply-dialog"},[_c('div',{staticClass:"apply-dialog-head"},[_c('p',{staticClass:"head-title"},[_vm._v("注销流程")]),_c('p',{staticClass:"head-describe"},[_vm._v("(①账号条件审核-②用户手机认证-③注销成功)")])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],staticClass:"apply-dialog-table"},[_c('p',{staticClass:"table-title"},[_vm._v("用户信息")]),_c('table',{staticClass:"table-box"},[_c('tr',[_c('td',[_vm._v("用户名: "+_vm._s(_vm.tableData && _vm.tableData.nickName))]),_c('td',[_vm._v("手机号(登录账号): "+_vm._s(_vm.tableData && _vm.tableData.mobile))]),_c('td',[_vm._v("邮箱: "+_vm._s(_vm.tableData && _vm.tableData.email))])]),_c('tr',[_c('td',[_vm._v("余额: ￥"+_vm._s(_vm.tableData && _vm.tableData.balance))]),_c('td',[_vm._v("未完成订单: "+_vm._s(_vm.tableData && _vm.tableData.umCompleteOrders))]),_c('td',[_vm._v("最近订单完成时间: "+_vm._s(_vm.tableData && _vm.latestTransactionDate(_vm.tableData.latestTransactionDate)))])])])]),_c('div',{staticClass:"apply-dialog-condition"},[_c('p',[_vm._v("注销账户需要同时满足以下条件")]),_c('ul',{staticClass:"condition-list"},_vm._l((_vm.condition),function(ref){
var code = ref.code;
var message = ref.message;
return _c('li',{key:code,class:{
              'list-item-success': _vm.iconCheck(code) && !_vm.iconLoading,
              'list-item-error': !_vm.iconCheck(code) && !_vm.iconLoading
            }},[_c('i',{staticClass:"list-icon",class:{
                'el-icon-loading': _vm.iconLoading,
                'el-icon-check': _vm.iconCheck(code) && !_vm.iconLoading,
                'el-icon-close': !_vm.iconCheck(code) && !_vm.iconLoading
              }}),_c('span',[_vm._v(_vm._s(message))])])}),0)]),_c('div',{staticClass:"apply-dialog-tip"},[_c('span',{staticClass:"el-icon-warning-outline"}),_c('span',[_vm._v("提示: 账号一旦注销成功，将无法恢复，注销操作不可逆。用户将无法登录此账号，请慎重操作。")])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.goBack}},[_vm._v("上一步")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.goAhead}},[_vm._v("下一步")])],1)]),_c('AuthenticationDialog',{attrs:{"authenticationDialogVisible":_vm.authenticationDialogVisible,"tableData":_vm.tableData},on:{"goBack":_vm.open,"update:authenticationDialogVisible":function($event){_vm.authenticationDialogVisible=$event},"update:authentication-dialog-visible":function($event){_vm.authenticationDialogVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }