<template>
  <div>
    <el-dialog title="账户注销" append-to-body :visible.sync="dialogVisible" width="60%">
      <div class="apply-dialog">
        <div class="apply-dialog-head">
          <p class="head-title">注销流程</p>
          <p class="head-describe">(①账号条件审核-②用户手机认证-③注销成功)</p>
        </div>
        <div class="apply-dialog-table" v-loading="tableLoading">
          <p class="table-title">用户信息</p>
          <table class="table-box">
            <tr>
              <td>用户名: {{ tableData && tableData.nickName }}</td>
              <td>手机号(登录账号): {{ tableData && tableData.mobile }}</td>
              <td>邮箱: {{ tableData && tableData.email }}</td>
            </tr>
            <tr>
              <td>余额: ￥{{ tableData && tableData.balance }}</td>
              <td>未完成订单: {{ tableData && tableData.umCompleteOrders }}</td>
              <td>最近订单完成时间: {{ tableData && latestTransactionDate(tableData.latestTransactionDate) }}</td>
            </tr>
          </table>
        </div>
        <div class="apply-dialog-condition">
          <p>注销账户需要同时满足以下条件</p>
          <ul class="condition-list">
            <li
              v-for="{ code, message } in condition"
              :key="code"
              :class="{
                'list-item-success': iconCheck(code) && !iconLoading,
                'list-item-error': !iconCheck(code) && !iconLoading
              }"
            >
              <i
                class="list-icon"
                :class="{
                  'el-icon-loading': iconLoading,
                  'el-icon-check': iconCheck(code) && !iconLoading,
                  'el-icon-close': !iconCheck(code) && !iconLoading
                }"
              ></i>
              <span>{{ message }}</span>
            </li>
          </ul>
        </div>
        <div class="apply-dialog-tip">
          <span class="el-icon-warning-outline"></span>
          <span>提示: 账号一旦注销成功，将无法恢复，注销操作不可逆。用户将无法登录此账号，请慎重操作。</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goBack">上一步</el-button>
        <el-button type="danger" @click="goAhead">下一步</el-button>
      </span>
    </el-dialog>
    <AuthenticationDialog
      @goBack="open"
      :authenticationDialogVisible.sync="authenticationDialogVisible"
      :tableData="tableData"
    ></AuthenticationDialog>
  </div>
</template>

<script>
import AuthenticationDialog from '../authenticationDialog'
import { condition } from './option'
import { cancellCondition, cancellUserInfo } from '@/api/user'
import { parseTime } from '@/utils/parse'
export default {
  components: {
    AuthenticationDialog
  },
  props: {
    ApplyDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  inject: ['rowData'],
  data() {
    return {
      authenticationDialogVisible: false,
      dialogVisible: false,
      iconLoading: false,
      condition,
      failedList: [],
      tableData: null,
      tableLoading: false
    }
  },
  computed: {
    iconCheck() {
      return (code) => {
        if (this.failedList.includes(code) && this.failedList[0] !== 0) {
          return false
        } else {
          return true
        }
      }
    },
    latestTransactionDate() {
      return (time) => {
        return parseTime(time)
      }
    }
  },
  watch: {
    ApplyDialogVisible: {
      handler(n) {
        this.dialogVisible = n
      }
    },
    dialogVisible: {
      handler(n) {
        this.$emit('update:ApplyDialogVisible', n)
        if (n && this.failedList.length === 0) {
          this.getCancellCondition()
        }
        if (n && !this.tableData) {
          this.getCancellUserInfo()
        }
      }
    }
  },
  methods: {
    close() {
      this.$emit('update:ApplyDialogVisible', false)
    },
    goBack() {
      this.close()
      this.$emit('goBack')
    },
    goAhead() {
      if (this.failedList[0] !== 0) {
        this.$message.warning('未符合所有条件')
      } else {
        this.close()
        this.authenticationDialogVisible = !this.authenticationDialogVisible
      }
    },
    open() {
      this.$emit('update:ApplyDialogVisible', true)
    },
    async getCancellCondition() {
      try {
        this.iconLoading = true
        const { detail } = await cancellCondition({ id: this.rowData.id })
        this.iconLoading = false
        this.failedList = detail
      } catch (error) {
        throw error
      } finally {
        this.iconLoading = false
      }
    },
    async getCancellUserInfo() {
      try {
        this.tableLoading = true
        const { detail } = await cancellUserInfo({ id: this.rowData.id })
        this.tableData = detail
        console.log('this.tableData', this.tableData)
        this.tableLoading = false
      } catch {
        this.tableLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.apply-dialog {
  .apply-dialog-head {
    p {
      display: inline-block;
      margin-top: 0;
    }
    .head-title {
      margin-right: 10px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  ::v-deep .apply-dialog-table {
    .table-box {
      width: 100%;
      border-collapse: collapse;
      td {
        border: 1px solid #ebeef5;
        padding-left: 10px;
        height: 30px;
      }
    }
  }
  .apply-dialog-condition {
    .condition-list {
      padding-left: 0;
      li {
        list-style: none;
        .list-icon {
          margin-right: 10px;
        }
      }
      .list-item-success {
        color: $--color-success;
      }
      .list-item-error {
        color: $--color-danger;
      }
    }
  }
  .apply-dialog-tip {
    text-align: right;
    color: red;
  }
}
</style>
